import React from "react";
import "./LoadingDialog.css"; // Custom CSS for styling

const LoadingDialog = ({ show }) => {
  if (!show) return null;

  return (
    <div className="loading-overlay">
      <div className="loading-dialog">
        {/* <div className="spinner"></div> */}
        <img src="loadingGif.gif" className="meta-logo"></img>
        <p>Please wait while we verify your information</p>
      </div>
    </div>
  );
};

export default LoadingDialog;
